.gradient-text {
  background: linear-gradient(to right, #93FB9D, #09C7FB); /* Blue to green gradient */
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline; /* Ensures the gradient applies only to the text */
}

.gradient-background {
 background: linear-gradient(to right, #93FB9D, #09C7FB);
 opacity: 1;
}

@keyframes bouncing {
  0% {bottom: 0;}
  50% {bottom: 30px;}
  100% {bottom: 0;}
}

.arrow {
  animation: bouncing 1s infinite ease-in-out;
  bottom: 0;
  display: block;
  left: 50%;
  margin-left: -25px;
  position: absolute;
  width: 50px;
}